import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from "../../components/AppContext";
import NavBar from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import { VideoSection } from '../../components/AboutSample/React/VideoSection';
import { Hero } from '../../components/AboutSample/React/Hero';
import { Hero3 } from '../../components/AboutSample/React/Hero3';
import { Hero2 } from '../../components/AboutSample/React/Hero2';
import { Hero4 } from '../../components/AboutSample/React/Hero4';
import { Feature } from '../../components/AboutSample/React/Feature';
import { Showcase } from '../../components/AboutSample/React/Showcase';
import { PagesShowcase } from '../../components/AboutSample/React/PagesShowcase';
import { DocsPreview } from '../../components/AboutSample/React/DocsPreview';
import { Comparison } from '../../components/AboutSample/React/Comparison';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import '../../styles/scss/style.scss'

const AdminContrastPro = () => {

  return (
    <AppContext>
               
      <Helmet>
        <title>Contrast Bootstrap 5 UI Kit Library</title>
        <meta name="title" content="Contrast Bootstrap 5 UI Kit Library" />
        <meta
          name="description"
          content="Well crafted Bootstrap 5 UI kit featuring over 2000+ basic components and 10000+ pro components to build your next landing, admin, SAAS, prelaunch, etc project in record time, using clean UI components"
        />
        <meta
          property="og:title"
          content="Contrast Bootstrap 5 UI Kit Library"
        />
        <meta
          property="og:description"
          content="Well crafted Bootstrap 5 UI kit featuring over 2000+ basic components and 10000+ pro components to build your next landing, admin, SAAS, prelaunch, etc project in record time, using clean UI components"
        />
        <meta
          property="twitter:title"
          content="Contrast Bootstrap 5 UI Kit Library"
        />
        <meta
          property="twitter:description"
          content="Well crafted Bootstrap 5 UI kit featuring over 2000+ basic components and 10000+ pro components to build your next landing, admin, SAAS, prelaunch, etc project in record time, using clean UI components"
        />
        <link rel="canonical" href="https://www.devwares.com/product/bootstrap-contrast/" />
        <meta property="og:url" content="https://www.devwares.com/product/bootstrap-contrast" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />
        <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />

      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="container">
              <NavBar user={user} />
            </div>
            <Hero />
            <VideoSection />
            <div className="mt-5">
              <FeaturedIn />
            </div>
            <Feature />
            <Hero3 />
            <Hero2 />
            <Hero4 />
            <PagesShowcase />
            <DocsPreview />
            <Showcase />
            <Comparison />
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default AdminContrastPro;
